<template>
  <div v-if="isAccessDenied"
    class="main-content">
    <h4>{{ accessDenied }}</h4>
  </div>
  <el-form v-else
    ref="marketplaceProfileForm"
    :model="marketplaceProfile"
    :rules="marketplaceProfileRules">
    <div v-loading="marketplaceData.length <= 1"
      class="main-content">
      <!-- Left column -->
      <div class="width-200px mr-20px">
        <router-link to="/marketplace">
          <div>
            <mu-mega-icon icon="MarketplaceLabel"
              height="70" />
          </div>
        </router-link>

        <!-- Nothing to display if there is no profile -->
        <template v-if="marketplaceProfile">
          <!-- Component which will be shown as required to collect the image -->
          <image-upload v-model="marketplaceProfile.image"
            :visible.sync="uploadDisplay" />

          <!-- Component for sending message to the seller. -->
          <contact-seller :visible.sync="showContactSeller"
            :product-uid="null"
            :marketplace-profile="marketplaceProfile" />

          <div v-if="editing && !marketplaceProfile.image"
            class="bordered-box info-box no-image mt-1"
            @click="uploadDisplay = true">
            <p>{{ $t('dashboard.marketplace-image') }}</p>
            <el-button class="mt-1"
              @click="uploadDisplay = true">
              {{ $t('marketplace.upload-logo') }}
            </el-button>
          </div>
          <img v-else
            :src="logoDisplay"
            :alt="$t('images.marketplace-profile-logo')"
            class="mt-1 marketplace-profile-logo"
            @error="setFallbackImage($event, '/fallback-images/mp-placeholder.png')">

          <div v-if="editing && marketplaceProfile.image"
            class="text-align-center full-width">
            <el-button @click="uploadDisplay = true">
              {{ $t('marketplace.change-logo') }}
            </el-button>
          </div>

          <!-- Display only stuff in the left column -->
          <el-collapse-transition>
            <div v-if="!editing"
              class="left-sections">
              <div v-if="marketplaceProfile.www">
                <h4>{{ $t('general.website') }}</h4>
                <a :href="marketplaceProfile.www"
                  target="_blank"
                  rel="nofollow"
                  class="word-wrap-break-word">{{ marketplaceProfile.www }}</a>
              </div>

              <div v-if="marketplaceProfile.phone">
                <h4>{{ $t('general.phone') }}</h4>
                <p>{{ marketplaceProfile.phone }}</p>
              </div>

              <div v-if="marketplaceProfile.providerTypes && marketplaceProfile.providerTypes.length > 0">
                <h4>{{ $tc('marketplace.pluralize-provider-type', marketplaceProfile.providerTypes.length) }}</h4>
                <ul>
                  <li v-for="(provider, index) in marketplaceProfile.providerTypes"
                    :key="index">
                    {{ provider.description }}
                  </li>
                </ul>
              </div>

              <div v-if="hasSocialMedia"
                class="social-media">
                <h4>{{ $t('marketplace.social') }}</h4>
                <a v-if="marketplaceProfile.facebook"
                  :href="marketplaceProfile.facebook"
                  target="_blank"
                  rel="nofollow">
                  <i class="fab fa-facebook color-facebook"
                    aria-hidden="true" />
                </a>
                <a v-if="marketplaceProfile.twitter"
                  :href="marketplaceProfile.twitter"
                  target="_blank"
                  rel="nofollow">
                  <i class="fab fa-x-twitter color-twitter"
                    aria-hidden="true" />
                </a>
                <a v-if="marketplaceProfile.linkedin"
                  :href="marketplaceProfile.linkedin"
                  target="_blank"
                  rel="nofollow">
                  <i class="fab fa-linkedin-in color-linkedin"
                    aria-hidden="true" /> </a>
              </div>

              <div v-if="hasHeadquarters">
                <h4>{{ $t('marketplace.headquarters') }}</h4>
                <p>{{ marketplaceProfile.street1 }}</p>
                <p>{{ marketplaceProfile.street2 }}</p>
                <p>{{ marketplaceProfile.city }}</p>
                <p>{{ marketplaceProfile.state }}</p>
                <p>{{ marketplaceProfile.country }}</p>
                <p>{{ marketplaceProfile.postcode }}</p>
              </div>
            </div>
          </el-collapse-transition>

          <!-- Stuff in the left column when editing -->
          <el-collapse-transition>
            <div v-if="editing"
              class="mt-1">
              <el-form-item :label="$t('general.website')"
                prop="www">
                <el-input v-model="marketplaceProfile.www"
                  placeholder="https://"
                  name="website"
                  data-demo="https://www.megaport.com/" />
              </el-form-item>

              <el-form-item prop="phone"
                class="unfloat-label">
                <template #label>
                  {{ $t('general.phone') }}
                  <el-tooltip placement="top"
                    :content="$t('billing-markets.phone-format')"
                    :open-delay="500">
                    <i class="fas fa-question-circle color-info popover-info-icon"
                      aria-hidden="true" />
                  </el-tooltip>
                </template>
                <el-input v-model="marketplaceProfile.phone"
                  data-demo="+61 7 12341234"
                  placeholder="+61 7 40309999"
                  name="phone" />
              </el-form-item>

              <template v-if="providerTypes && providerTypes.length > 0">
                <el-form-item :label="$tc('marketplace.pluralize-provider-type', 2)"
                  prop="providerSelected">
                  <el-checkbox-group v-model="providerSelected"
                    class="d-inline-block">
                    <el-checkbox v-for="type in providerTypes"
                      :key="type.id"
                      :name="`providerType-${type.id}`"
                      :label="type.id"
                      class="line-height-0 d-flex mb-1 text-wrap">
                      {{ type.description }}
                    </el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </template>

              <el-form-item prop="facebook"
                :label="$t('marketplace.social')">
                <el-input v-model="marketplaceProfile.facebook"
                  type="url"
                  data-demo="https://facebook.com/megaport"
                  name="facebook"
                  placeholder="https://facebook.com"
                  class="input-stack"
                  prefix-icon="fab fa-facebook-f color-facebook" />
              </el-form-item>
              <el-form-item prop="twitter">
                <el-input v-model="marketplaceProfile.twitter"
                  type="url"
                  data-demo="https://x.com/megaportnetwork"
                  name="twitter"
                  placeholder="https://x.com"
                  class="input-stack"
                  prefix-icon="fab fa-x-twitter color-twitter" />
              </el-form-item>
              <el-form-item prop="linkedin">
                <el-input v-model="marketplaceProfile.linkedin"
                  type="url"
                  data-demo="https://linkedin.com/company/megaport"
                  name="linkedin"
                  placeholder="https://linkedin.com"
                  class="input-stack"
                  prefix-icon="fab fa-linkedin-in color-linkedin" />
              </el-form-item>

              <el-form-item prop="headquarters"
                :label="$t('marketplace.headquarters')">
                <el-input v-model="marketplaceProfile.street1"
                  type="text"
                  data-demo="308 Negra Arroyo Lane"
                  name="street1"
                  class="input-stack"
                  :placeholder="$t('marketplace.address-1')" />
                <el-input v-model="marketplaceProfile.street2"
                  type="text"
                  data-demo="Cnr McGill St"
                  name="street2"
                  class="input-stack"
                  :placeholder="$t('marketplace.address-2')" />
                <el-input v-model="marketplaceProfile.city"
                  type="text"
                  data-demo="Albuquerque"
                  name="city"
                  class="input-stack"
                  :placeholder="$t('marketplace.city')" />
                <el-input v-model="marketplaceProfile.state"
                  type="text"
                  data-demo="New Mexico"
                  name="state"
                  class="input-stack"
                  :placeholder="$t('marketplace.state')" />
                <el-input v-model="marketplaceProfile.country"
                  type="text"
                  data-demo="United States"
                  name="country"
                  class="input-stack"
                  :placeholder="$t('marketplace.country')" />
                <el-input v-model="marketplaceProfile.postcode"
                  type="text"
                  data-demo="87104"
                  name="postcode"
                  class="input-stack"
                  :placeholder="$t('marketplace.postcode')" />
              </el-form-item>
            </div>
          </el-collapse-transition>
        </template>
      </div>

      <!-- Right column -->
      <div class="right-column">
        <!-- Special message if it's your own profile and you're not editing -->
        <el-collapse-transition>
          <div v-if="!editing && ownProfile"
            class="bordered-box info-box mb-2">
            <p>{{ $t('marketplace.marketplace-intro') }}</p>

            <p>{{ $t('marketplace.point-click') }}</p>
          </div>
        </el-collapse-transition>

        <!-- Special message if you are not editing and it's your profile but it's not active -->
        <el-collapse-transition>
          <template v-if="!editing && ownProfile && marketplaceProfile && !marketplaceProfile.active">
            <div class="bordered-box warning-box mt-1 mx-0 mb-2">
              <p>{{ $t('marketplace.currently-private') }}</p>
              <template v-if="canEdit">
                <p>{{ $t('marketplace.easy-get-connected') }}</p>
                <div class="text-align-right">
                  <el-button name="edit-profile-1"
                    type="primary"
                    @click="editProfile()">
                    {{ $t('marketplace.edit-profile') }}
                  </el-button>
                </div>
              </template>
              <template v-else>
                <p>{{ $t('marketplace.no-edit-permission') }}</p>
              </template>
            </div>
          </template>
        </el-collapse-transition>

        <el-collapse-transition>
          <div v-if="!marketplaceProfile"
            class="bordered-box warning-box mb-2">
            <p>{{ $t('marketplace.no-profile') }}</p>

            <p v-if="canEdit">
              {{ $t('marketplace.loading-editor') }}
            </p>
            <p v-else>
              {{ $t('marketplace.no-create-permission') }}
            </p>
          </div>
        </el-collapse-transition>

        <!-- The rest of the right side information when not editing -->
        <template v-if="marketplaceProfile">
          <el-collapse-transition>
            <div v-if="!editing">
              <h2>{{ marketplaceProfile.companyName }}</h2>
              <p v-if="hyphenedName !== '' && ownProfile">
                <span class="font-weight-400">
                  {{ $t('marketplace.profile-url') }}
                </span>
                {{ hyphenedName }}
                <el-tooltip placement="top"
                  :content="$t('general.copy-to-clipboard')"
                  :open-delay="500">
                  <el-button size="mini"
                    @click="copyToClipboard(hyphenedName)">
                    <i class="far fa-clipboard"
                      aria-hidden="true" />
                  </el-button>
                </el-tooltip>
              </p>

              <p class="white-space-pre-line word-break-normal line-height-1-375em">
                {{ marketplaceProfile.companyBio }}
              </p>
              <div class="text-align-right mb-1">
                <el-button type="primary"
                  @click="showContactSeller = true">
                  <i class="fal fa-envelope"
                    aria-hidden="true" /> {{ $t('marketplace.contact') }}
                </el-button>
              </div>
            </div>
          </el-collapse-transition>
        </template>

        <!-- The right side information when editing -->
        <el-collapse-transition>
          <div v-if="editing">
            <h2 class="color-text-primary text-align-center mb-2">
              {{ $t('marketplace.edit-profile') }}
            </h2>
            <el-form-item :label="$t('marketplace.visibility')"
              prop="active"
              label-width="215px">
              <el-radio-group v-model="marketplaceProfile.active"
                name="marketplaceVisibility">
                <el-radio-button class="inverse-padding"
                  :label="true"
                  data-name="market-public">
                  <el-tooltip placement="top"
                    :content="$t('marketplace.visible')"
                    :open-delay="500">
                    <span>{{ $t('general.public') }}</span>
                  </el-tooltip>
                </el-radio-button>
                <el-radio-button :label="false"
                  class="inverse-padding"
                  data-name="market-private">
                  <el-tooltip placement="top"
                    :content="$t('marketplace.hidden')"
                    :open-delay="500">
                    <span>{{ $t('general.private') }}</span>
                  </el-tooltip>
                </el-radio-button>
              </el-radio-group>
            </el-form-item>

            <el-form-item :label="$t('general.company-name')"
              prop="companyName"
              label-width="215px">
              <el-input v-model="marketplaceProfile.companyName"
                type="email"
                name="companyName"
                data-demo="Example Company Name" />
            </el-form-item>

            <el-form-item :label="$t('marketplace.company-bio')"
              prop="companyBio"
              label-width="215px">
              <el-input v-model="marketplaceProfile.companyBio"
                type="textarea"
                name="companyBio"
                data-demo="This is an example company bio."
                :placeholder="$t('marketplace.bio-length')"
                :autosize="{ minRows: 3}" />
            </el-form-item>
            <p :class="!marketplaceProfile.companyBio || marketplaceProfile.companyBio.trim().split(/[\s]+/).length <= maxWordLength ? 'color-success' : 'color-danger'"
              class="text-align-right full-width mb-1 mx-0 mt-negative-1 font-weight-400">
              {{ wordCount }}
            </p>

            <el-form-item :label="$t('marketplace.contact-email')"
              prop="email"
              label-width="215px">
              <el-input v-model="marketplaceProfile.email"
                name="contactEmail"
                data-demo="mail@example.com" />
            </el-form-item>
          </div>
        </el-collapse-transition>

        <template v-if="profileServices && profileServices.length > 0">
          <h4>{{ $t('marketplace.services') }}</h4>
          <service-profile v-for="(item, index) in profileServices"
            :key="item.productUid"
            v-model="marketplaceProfile.eServices[index]"
            :init-value="item"
            :editing="editing"
            :index="index"
            :marketplace-profile="marketplaceProfile"
            :service-types="serviceTypes"
            :is-duplicate-title="isDuplicateTitleAtIndex(index)"
            @input="servicesChanged" />
        </template>
        <div v-if="editing && hasInactiveService"
          class="bordered-box warning-box mt-2">
          <p>{{ $t('marketplace.private-destinations') }}</p>
        </div>
      </div>

      <div class="text-align-right full-width mt-2">
        <div v-if="!editing">
          <!-- Needs to be wrapped in a div to prevent bug where buttons are small and empty -->
          <template v-if="recentProviders && recentProviders.length > 0">
            <el-dropdown trigger="hover"
              placement="top"
              @command="handleProviderClick">
              <el-button class="mr-0-5">
                {{ $t('marketplace.recently-viewed') }}
                <i class="el-icon-arrow-down"
                  aria-hidden="true" />
              </el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item v-for="recent in recentProviders"
                    :key="recent.companyUid"
                    :command="recent.link">
                    <el-tooltip placement="right"
                      :content="recent.companyBio || $t('target-select.no-bio')"
                      :open-delay="500">
                      <div class="flex-row-centered">
                        <img :src="recent.logo"
                          :alt="`${recent.companyName} ${$t('images.company-logo')}`"
                          class="other-logo-image"
                          @error="setFallbackImage($event, '/fallback-images/mp-placeholder.png')">
                        <h4>{{ recent.companyName }}</h4>
                      </div>
                    </el-tooltip>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </template>

          <el-dropdown trigger="hover"
            placement="top"
            @command="handleProviderClick">
            <el-button class="mr-0-5">
              {{ $t('marketplace.similar-providers') }}
              <i class="el-icon-arrow-down"
                aria-hidden="true" />
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item v-for="provider in rankedMarketplace"
                  :key="provider.companyUid"
                  :command="provider.link">
                  <el-tooltip placement="right"
                    :content="provider.companyBio || $t('target-select.no-bio')"
                    :open-delay="500">
                    <div class="flex-row-centered">
                      <img :src="provider.logo"
                        :alt="`${provider.companyName} ${$t('images.provider-logo')}`"
                        class="other-logo-image"
                        @error="setFallbackImage($event, '/fallback-images/mp-placeholder.png')">
                      <h4>{{ provider.companyName }}</h4>
                    </div>
                  </el-tooltip>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>

          <el-button @click="$router.push('/marketplace', () => {})">
            <i class="fas fa-arrow-circle-left"
              aria-hidden="true" /> {{ $t('marketplace.company-list') }}
          </el-button>

          <el-button v-if="ownProfile && canEdit"
            name="edit-profile-2"
            type="primary"
            @click="editProfile()">
            {{ marketplaceProfile ? $t('marketplace.edit-profile') : $t('marketplace.create-profile') }}
          </el-button>
        </div>

        <template v-else>
          <el-button @click="cancelEditing">
            {{ $t('general.cancel') }}
          </el-button>
          <el-button type="primary"
            :loading="savingProfile"
            data-name="saveProfile"
            @click="setMarketplaceProfile">
            {{ $t('marketplace.save-profile') }}
          </el-button>
        </template>
      </div>
    </div>
  </el-form>
</template>

<script>
import captureSentryError from '@/utils/CaptureSentryError.js'
import { setFallbackImage } from '@/utils/fallbackImage'
import { captureEvent } from '@/utils/analyticUtils'
import { resolveServicesPage } from '@/utils/MapDataUtils.js'

import ServiceProfileComponent from '@/components/marketplace/ServiceProfile.vue'
import ImageUploadComponent from '@/components/marketplace/ImageUpload.vue'
import ContactSellerComponent from '@/components/marketplace/MarketplaceContactSeller.vue'

import { AWS_COMPANY } from '@/Globals.js'
import { deepClone, capitalizeFirstOnly, slug, copyToClipboard } from '@/helpers.js'
import { PHONE_NUMBER_REGEX } from '@/validators'
import { escape } from 'lodash'
import { mapActions, mapGetters, mapState } from 'vuex'
import sdk from '@megaport/api-sdk'

export default {
  name: 'MarketplaceProfile',

  components: {
    'service-profile': ServiceProfileComponent,
    'image-upload': ImageUploadComponent,
    'contact-seller': ContactSellerComponent,
  },

  inject: ['isFeatureEnabled'],

  beforeRouteEnter(_to, _from, next) {
    next(vm => {
      if (vm.isOwnProfile && vm.isManagedAccount) {
        next(resolveServicesPage())
      }
    })
  },

  data() {
    return {
      clonedMarketplaceProfile: {
        companyUid: null,
        companyName: null,
        email: null,
        www: null,
        phone: null,
        providerTypes: [],
        facebook: null,
        twitter: null,
        logo: null,
        logoImage: null,
        linkedin: null,
        street1: null,
        street2: null,
        city: null,
        state: null,
        country: null,
        postcode: null,
        companyBio: null,
        services: {},
        active: false,
        eServices: [],
      },
      marketplaceProfileRules: {
        active: {
          required: true,
          message: this.$t('validations.required', { thing: this.$t('marketplace.visibility') }),
          trigger: 'change',
        },
        www: {
          required: true,
          message: this.$t('validations.required', { thing: this.$t('general.website') }),
          trigger: 'blur',
        },
        phone: [
          {
            required: true,
            message: this.$t('validations.required', { thing: this.$t('billing-markets.phone-number') }),
            trigger: 'blur',
          },
          { pattern: PHONE_NUMBER_REGEX, message: this.$t('billing-markets.phone-format'), trigger: 'blur' },
        ],
        facebook: {
          pattern: /^(https?:\/\/)?(([a-z]{1,2}|www)\.)?(facebook\.com|fb\.(me|com)|m\.facebook\.com)(\.[a-z]{0,2})?\/[^\s]+$/i,
          message: this.$t('validations.url-format', { site: 'Facebook' }),
          trigger: 'blur',
        },
        twitter: {
          // API: Get BE to accept x.com as a valid URL for the PUT v2/marketplace/profile endpoint
          pattern: /^(https?:\/\/)?(([a-z]{1,2}|www)\.)?((twitter|x)\.com)(\.[a-z]{0,2})?\/[^\s]+$/i,
          message: this.$t('validations.url-format', { site: 'Twitter' }),
          trigger: 'blur',
        },
        linkedin: {
          pattern: /^(https?:\/\/)?(([a-z]{1,2}|www)\.)?(linkedin\.com)(\.[a-z]{0,2})?\/[^\s]+$/i,
          message: this.$t('validations.url-format', { site: 'LinkedIn' }),
          trigger: 'blur',
        },
        companyName: {
          required: true,
          pattern: '^[A-z0-9].{2,64}$',
          message: this.$t('validations.valid-company'),
          trigger: 'blur',
        },
        companyBio: [
          {
            required: true,
            message: this.$t('validations.required', { thing: this.$t('marketplace.company-bio') }),
            trigger: 'blur',
          },
          { validator: this.validateCompanyBio, trigger: 'change' },
        ],
        email: {
          required: true,
          message: this.$t('validations.required', { thing: this.$t('general.email') }),
          trigger: 'blur',
        },
      },
      targetSelect: null,
      editing: false,
      recentItems: null,
      maxWordLength: 200,
      providerTypes: [],
      uploadDisplay: false,
      newProfile: false,
      bioExpanded: false,
      profileLoaded: false,
      showContactSeller: false,
      duplicateServiceTitles: [],
      savingProfile: false,
    }
  },

  computed: {
    ...mapState('Services', ['services']),
    ...mapState('Company', { company: state => state.data }),
    ...mapState('Marketplace', ['marketplaceData']),
    ...mapGetters('ApplicationContext', ['isManagedContext']),
    ...mapGetters('Auth', ['hasAuth', 'isManagedAccount']),
    ...mapGetters('Marketplace', { localProfile: 'marketplaceProfile' }),
    ...mapGetters('Services', ['findPort', 'canPortBeConnectedToByVxc']),
    isAccessDenied() {
      return this.isFeatureEnabled('MARKETPLACE_PROFILE_ENABLED') && this.isOwnProfile && this.isManagedContext
    },
    isOwnProfile() {
      return this.$route.path.includes('/marketplace/profile')
    },
    accessDenied() {
      const pageName = this.$t(this.$route.meta.title)
      return this.$t('general.feature-flag-access-error', { pageName })
    },
    logoDisplay() {
      if (this.marketplaceProfile.image && this.marketplaceProfile.image !== 'true') {
        return this.marketplaceProfile.image
      }
      return this.marketplaceProfile.logo
    },
    hasInactiveService() {
      for (const service of this.profileServices) {
        if (!service.portInfo.marketplaceVisibility && this.company.companyUid === service.portInfo.companyUid) {
          return true
        }
      }
      return false
    },
    ownProfile() {
      return this.company && this.marketplaceProfile && this.company.companyUid === this.marketplaceProfile.companyUid
    },
    portPins() {
      // todo
      // [ { latitude: -27.470125, longitude: 153.021072 }, { latitude: 45.516136, longitude: -73.656830 }, { latitude: -36.848461, longitude: 174.763336 } ]
      return []
    },
    canEdit() {
      return this.hasAuth('company_admin')
    },
    marketplaceProfile() {
      if (this.$route.path.split('/')[1] !== 'exchange' && this.$route.path.split('/')[1] !== 'marketplace') {
        return false
      }

      // If we are editing, then we don't want to upset any changes, so just return the cloned marketplace profile.
      if (this.editing) {
        return this.clonedMarketplaceProfile
      }

      const companyName = this.$route.params.companyPath
      let mp = null

      // If it's our own company profile, try to load it
      if (companyName === 'profile') {
        if (this.company?.companyUid) {
          mp = this.getMarketplaceProfileByCompanyUid(this.company.companyUid)
        }

        // Handle the case where we don't have a profile, but the core information required is available
        if (!mp && this.company?.name && this.marketplaceData.length && this.profileLoaded && this.canEdit) {
          // This will set up an empty cloned profile, which can potentially be edited later
          this.setupClonedMarketplaceProfile()
          return this.clonedMarketplaceProfile
        }
      } else {
        // Try to load the profile for the company in the URL
        mp = this.marketplaceData.find(p => companyName === slug(p.companyName))
      }

      // There was a marketplace profile found, so make a copy that we can work with, and make sure it has the necessary
      // data.
      if (mp) {
        mp = deepClone(mp)

        if (!mp.services) {
          mp.services = []
        }
        if (!mp.eServices) {
          // Make sure that the editable profiles are ready to go
          this.populateEServicesForProfile(mp)
        }
      }
      return mp
    },
    providerSelected: {
      get() {
        if (!this.marketplaceProfile.providerTypes) return []
        return this.marketplaceProfile.providerTypes.map(a => a.id)
      },
      set(v) {
        this.marketplaceProfile.providerTypes = v.map(d => {
          const f = this.providerTypes.find(pt => d === pt.id)
          if (f)
            return {
              id: f.id,
              description: f.description,
            }
        })
      },
    },
    isAws() {
      return this.marketplaceProfile.companyUid === AWS_COMPANY
    },
    profileServices() {
      return this.getProfileServicesForMarketplaceProfile(this.marketplaceProfile)
    },
    hyphenedName() {
      if (!this.marketplaceProfile.companyName) return ''
      return `${location.origin}/marketplace/${slug(this.marketplaceProfile.companyName)}`
    },
    hasSocialMedia() {
      return this.marketplaceProfile.twitter || this.marketplaceProfile.linkedin || this.marketplaceProfile.facebook
    },
    rankedMarketplace() {
      if (!this.marketplaceData || !this.marketplaceProfile) return []

      const currentLocations = this.getUniqueLocations(this.marketplaceProfile.services)
      return this.marketplaceData
        .map(profile => {
          let rank = 0
          // 3 points for each provider type matching
          profile.providerTypes.forEach(provider => {
            if (this.marketplaceProfile.providerTypes.indexOf(provider) >= 0) {
              rank = rank + 3
            }
          })
          // 1 point for each matching region
          const profileLocations = this.getUniqueLocations(profile.services)
          currentLocations.forEach(loc => {
            if (profileLocations.indexOf(loc) >= 0) {
              rank++
            }
          })

          return Object.assign({}, profile, {
            rank: rank,
            link: `/marketplace/${slug(profile.companyName)}`,
          })
        })
        .sort((a, b) => {
          if (a.rank > b.rank) return 1
          if (a.rank < b.rank) return -1
          return 0
        })
        .slice(0, 5)
    },
    recentProviders() {
      this.fetchRecentProfiles()
      if (this.recentItems && this.marketplaceProfile) {
        return this.recentItems.filter(s => s.companyUid !== this.marketplaceProfile.companyUid).reverse()
      }
      return []
    },
    emailLink() {
      return `/marketplace/${this.$route.params.companyPath}/contact`
    },
    wordCount() {
      let count = 0
      if (this.marketplaceProfile.companyBio && this.marketplaceProfile.companyBio.trim() !== '') {
        count = this.marketplaceProfile.companyBio.trim().split(/[\s]+/).length
      }
      return this.$t('general.word-counter', { count, maxWords: this.maxWordLength })
    },
    serviceTypes() {
      let st = []
      if (this.marketplaceProfile.providerTypes)
        this.marketplaceProfile.providerTypes.forEach(pt => {
          const fpt = this.providerTypes.find(p => p.id === pt.id)
          if (fpt) {
            st = st.concat(fpt.serviceTypes)
          }
        })
      return st
    },
    /** Returns true if the profile has filled in any of the headquarter fields. */
    hasHeadquarters() {
      return this.marketplaceProfile.street1
        || this.marketplaceProfile.street2
        || this.marketplaceProfile.city
        || this.marketplaceProfile.state
        || this.marketplaceProfile.country
        || this.marketplaceProfile.postcode
    },
  },

  created() {
    this.marketplaceData.length <= 1 ? this.loadMarketplaceProfiles() : this.profileLoaded = true
  },

  mounted() {
    window.mpApp.$on('marketplace-profile-updated', success => {
      this.profileChanged(success)
    })

    window.mpApp.$on('marketplace-profile-loaded', success => {
      if (success) {
        this.profileInitialised()
      }
    })
  },

  beforeDestroy() {
    window.mpApp.$off('marketplace-profile-updated')
    window.mpApp.$off('marketplace-profile-loaded')
  },

  beforeUpdate() {
    this.saveRecentProfile()
  },

  methods: {
    ...mapActions('Marketplace', ['updateMarketplaceProfile', 'loadMarketplaceProfiles']),
    setFallbackImage,
    copyToClipboard,
    /**
     * Validates the company bio field ensuring it is not empty and is below the max word count.
     * @param {Object} _rule
     * @param {string} value
     * @param {Function} callback
     */
    validateCompanyBio(_rule, value, callback) {
      if (!value) {
        callback(this.$t('validations.required', { thing: this.$t('marketplace.company-bio') }))
      } else if (value.length > 2000) {
        callback(this.$tc('validations.max-length', 2000, { max: 2000 }))
      } else if (value.trim().split(/[\s]+/).length > this.maxWordLength) {
        callback(this.$t('validations.bio-too-long'))
      } else {
        callback()
      }
    },
    getMarketplaceProfileByCompanyUid(companyUid) {
      return this.marketplaceData.find(profile => profile.companyUid === companyUid)
    },
    servicesChanged() {
      const titleCount = this.marketplaceProfile.eServices.reduce((collected, aService) => {
        const casedTitle = capitalizeFirstOnly(aService.title)
        return {
          ...collected,
          [casedTitle]: (collected[casedTitle] || 0) + 1,
        }
      }, {})
      const duplicates = Object.keys(titleCount).filter(a => titleCount[a] > 1)
      this.duplicateServiceTitles = duplicates
    },
    isDuplicateTitleAtIndex(index) {
      if (!this.marketplaceProfile.eServices[index] || !this.editing) {
        return false
      }
      const title = capitalizeFirstOnly(this.marketplaceProfile.eServices[index].title)
      return this.duplicateServiceTitles.includes(title)
    },
    handleProviderClick(profileUrl) {
      this.$router.push(profileUrl, () => {
        // empty function is intentional
      })
    },
    setupClonedMarketplaceProfile() {
      this.clonedMarketplaceProfile.companyUid = this.company.companyUid
      this.clonedMarketplaceProfile.companyName = this.company.name
      this.clonedMarketplaceProfile.services = {}
      sdk.instance
        .marketplace()
        .providerTypes()
        .then(types => {
          this.providerTypes = types
        })
        .catch(e => {
          captureSentryError(e)
        })
      this.newProfile = true
      this.editing = true
      this.populateEServicesForProfile(this.clonedMarketplaceProfile)
    },
    populateEServicesForProfile(profile) {
      profile.eServices = this.getProfileServicesForMarketplaceProfile(profile)
    },
    getProfileServicesForMarketplaceProfile(profile) {
      if (!profile?.services) {
        return []
      }

      // Services are stored as an object by UID key, so map them out from the object into an array
      const viewable = Object.keys(profile.services)
        .map(key => {
          // Sometimes the port can be undefined (ENG-5407), so assign an empty object
          const portInfo = this.findPort(key) || {}
          return Object.assign(
            // Get the key from the services object and make it available in the array
            { productUid: key },
            // Get the value for the object
            profile.services[key],
            // Override any values we received by looking up the full port object
            { portInfo: portInfo }
          )
        })
        .filter(vp => {
          // If we're editing our own profile, always include it. If we have the port info and the location
          // to be able to make a connection, then include it.
          return this.editing || vp.portInfo?.locationId
        })

      // If we're editing, then we need to make sure that all the entries are included even if there wasn't any
      // data previously, since we may want to edit it and make it available
      if (this.editing) {
        // Services is our top level services since we can only be editing our own profile.
        this.services.forEach(aService => {
          if (!viewable.find(vp => aService.productUid === vp.productUid) && aService.productType === this.G_PRODUCT_TYPE_MEGAPORT) {
            viewable.push({
              contactEmail: '',
              description: '',
              metadata: null,
              portInfo: aService,
              serviceTypes: [],
              title: aService.title || aService.productName, // Initialize the title with the product name from the port if no title found
            })
          }
        })
      }

      // Make sure the destination port is something we can connect to by VXC - this is just used to sort the ports
      // to show the connectable ones at the top.
      const mapped = viewable.map(s => {
        s.connectable = this.services.filter(service => {
          return this.canPortBeConnectedToByVxc(service, s.portInfo)
        })
        return s
      })

      const readyPorts = mapped
        .filter(s => {
          // Only include the primary port of a LAG
          if (s.portInfo.aggregationId && !s.portInfo.lagPrimary) return false
          // Exclude any ports that are in Design or currently deploying
          return ![
            this.G_PROVISIONING_DESIGN,
            this.G_PROVISIONING_DEPLOYABLE,
          ].includes(s.portInfo.provisioningStatus)
        })
        .sort((a, b) => {
          if (a.connectable > b.connectable) return -1
          if (a.connectable < b.connectable) return 1
          return 0
        })

      return readyPorts
    },
    // Called internally when all validations and confirmations have passed.
    performSaveMarketplaceProfile() {
      this.savingProfile = true

      captureEvent('marketplace-profile.save-profile.click', { new: this.newProfile })

      const mpClone = { ...this.marketplaceProfile }
      delete mpClone.eServices
      const payload = {
        profile: mpClone,
        services: this.marketplaceProfile.eServices,
      }

      this.updateMarketplaceProfile(payload)
    },
    setMarketplaceProfile() {
      if (!this.editing) return false

      this.$refs.marketplaceProfileForm.validate(valid => {
        if (!valid) {
          const props = {
            title: this.$t('validations.failed'),
            message: this.$t('validations.correct-issues'),
            type: 'error',
            duration: 3000,
          }
          this.$notify(props)
          return false
        }
        if (this.duplicateServiceTitles.length) {
          let message = `${this.$t('marketplace.duplicated-names')}<br/>`
          for (const title of this.duplicateServiceTitles) {
            message += `<span class="font-weight-500 px-2">${escape(title)}</span><br/>`
          }
          message += this.$t('marketplace.duplicate-name-warning')
          this.$confirm(message, this.$t('marketplace.duplicate-name'), {
            confirmButtonText: this.$t('general.yes'),
            cancelButtonText: this.$t('general.no'),
            dangerouslyUseHTMLString: true,
            type: 'warning',
            showClose: false,
            closeOnClickModal: false,
          })
            .then(() => {
              this.performSaveMarketplaceProfile()
            })
            .catch(() => {
              // empty function is intentional
            })
        } else {
          this.performSaveMarketplaceProfile()
        }
      })
    },
    profileInitialised() {
      this.profileLoaded = true
      // copy the services over to the clone to ensure naming from marketplace is used instead of default/product naming
      if (this.clonedMarketplaceProfile.services && this.clonedMarketplaceProfile.services.length === 0) {
        this.clonedMarketplaceProfile.services = deepClone(this.localProfile.services)
      }
    },
    profileChanged(success) {
      this.editing = !success
      this.savingProfile = false
    },
    editProfile() {
      this.clonedMarketplaceProfile = deepClone(this.localProfile)
      if (!this.clonedMarketplaceProfile.companyName && this.company?.companyUid) {
        // there's no existing profile for the company (localProfile was empty), so we need to pre-fill it with some necessary data
        this.clonedMarketplaceProfile.companyUid = this.company.companyUid
        this.clonedMarketplaceProfile.companyName = this.company.name
        this.clonedMarketplaceProfile.services = []
      }
      this.populateEServicesForProfile(this.clonedMarketplaceProfile)
      this.editing = true
      sdk.instance
        .marketplace()
        .providerTypes()
        .then(types => {
          this.providerTypes = types
        })
        .catch(e => {
          captureSentryError(e)
        })
      if (this.$route.params.companyPath !== 'profile') {
        this.$router.push('/marketplace/profile', () => {
          // empty function is intentional
        })
        return true
      }
    },
    cancelEditing() {
      // discard any changes and return to read only/view mode
      if (this.newProfile) {
        return this.$router.push('/dashboard', () => {
          // empty function is intentional
        })
      }
      this.editing = false
    },
    getUniqueLocations(services) {
      if (!services) {
        return []
      }
      const locations = []
      Object.keys(services).forEach(key => {
        const portInfo = this.findPort(key) || null
        if (portInfo) {
          locations.push(portInfo.locationId)
        }
      })
      return locations.unique()
    },
    fetchRecentProfiles() {
      const items = []
      const recent = JSON.parse(localStorage.getItem(`_mpRecentProviders_${sdk.instance.credentials.personUid}`))
      if (recent) {
        try {
          recent.forEach(companyUid => {
            const provider = this.getMarketplaceProfileByCompanyUid(companyUid)
            if (provider)
              items.push(Object.assign({}, provider, {
                  link: `/marketplace/${slug(provider.companyName)}`,
                }))
          })
        } catch (e) {
          console.error('ERROR', e)
        }
      }
      this.recentItems = items
    },
    saveRecentProfile() {
      if (!this.marketplaceProfile) return // Don't save if no profile

      // Get the current list
      let recentItems = JSON.parse(localStorage.getItem(`_mpRecentProviders_${sdk.instance.credentials.personUid}`))
      if (!recentItems) {
        recentItems = []
      }
      if (recentItems.indexOf(this.marketplaceProfile.companyUid) >= 0) return // Already in the list

      const maxRecentItems = 3
      recentItems = recentItems.slice(recentItems.length - maxRecentItems + 1, recentItems.length)
      recentItems.push(this.marketplaceProfile.companyUid)
      localStorage.setItem(`_mpRecentProviders_${sdk.instance.credentials.personUid}`, JSON.stringify(recentItems))

      this.fetchRecentProfiles()
    },
  },
}
</script>

<style lang="scss" scoped>
.main-content {
  max-width: 900px;
  min-width: 400px;
  margin: 20px auto;
  padding: 20px;
  background-color: var(--color-white);
  border: 1px solid var(--border-color-base);
  border-radius: var(--border-radius-base);
  display: flex;
  flex-wrap: wrap;
}

.bordered-box {
  padding: 1rem 2rem;
  &.warning-box {
    border: 1px solid var(--color-warning);
  }
  &.info-box {
    border: 1px solid var(--color-info);
  }
  border-radius: var(--border-radius-base);
  p {
    margin-block: 1rem;
  }
}

.no-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(200px - 4rem);
  height: calc(200px - 2rem);
  cursor: pointer;
  &:hover {
    border-color: var(--color-primary);
  }
}

.left-sections div {
  margin: 2rem 0;
  ul {
    padding-left: 2rem;
    margin: 0;
  }
  li {
    font-weight: 300;
  }
  p {
    margin: 0;
  }
}

.social-media {
  font-size: 1.75rem;
  a {
    margin: 0 1rem 0 0;
  }
}

.input-stack {
  margin: 0.5rem 0;
}

.other-logo-image {
  margin-right: 1rem;
  width: 80px;
  height: 80px;
  background-color: white;
}

.marketplace-profile-logo {
  width: 200px;
  height: 200px;
}

.right-column {
  width: calc(100% - 220px);
}

.width-200px {
  width: 200px;
}

.mr-20px {
  margin-right: 20px;
}

.word-wrap-break-word {
  word-wrap: break-word;
}

.line-height-1-375em {
  line-height: 1.375em;
}

.text-wrap {
  text-wrap: wrap;
}
</style>
