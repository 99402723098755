<template>
  <el-dialog v-loading="sending"
    :visible="visible"
    :before-close="handleClose"
    :title="$t('marketplace.contact-company', {company: marketplaceProfile.companyName})"
    :element-loading-text="$t('marketplace.sending')">
    <el-form ref="contactSellerForm"
      :model="contactSellerForm"
      :rules="contactSellerRules"
      label-width="150px">
      <el-form-item :label="$t('general.service')"
        prop="selectedServiceUid">
        <el-select v-model="contactSellerForm.selectedServiceUid"
          class="width-290px">
          <el-option :label="$t('marketplace.general-info')"
            :value="null" />
          <el-option v-for="service in profileServices"
            :key="service.productUid"
            :label="marketplaceName(service)"
            :value="service.productUid" />
        </el-select>
      </el-form-item>

      <el-form-item :label="$t('general.to')">
        <div class="font-weight-300">
          {{ serviceEmail }}
        </div>
      </el-form-item>

      <el-form-item v-if="account"
        :label="$t('general.from')">
        <div class="from-details">
          {{ fromDetails }}
        </div>
      </el-form-item>

      <el-form-item :label="$t('general.message')"
        prop="message">
        <el-input v-model="contactSellerForm.message"
          type="textarea"
          name="message"
          :placeholder="$t('general.message')"
          :autosize="{ minRows: 3}" />
      </el-form-item>

      <el-form-item prop="sendCopy">
        <el-checkbox v-model="contactSellerForm.sendCopy">
          {{ $t('marketplace.send-copy') }}
        </el-checkbox>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="setVisible(false)">
        {{ $t('general.cancel') }}
      </el-button>
      <el-button type="primary"
        @click="sendEmail">
        {{ $t('general.send') }}
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
import sdk from '@megaport/api-sdk'
import { escape } from 'lodash'

// This is set up for use of visible.sync, so when the dialog needs to close it emits the update message.
export default {
  name: 'MarketplaceContactSeller',

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    productUid: {
      type: String,
      default: null,
    },
    marketplaceProfile: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      contactSellerForm: {
        selectedServiceUid: this.productUid || null,
        message: null,
        sendCopy: true,
      },
      contactSellerRules: {
        message: { required: true, message: this.$t('marketplace.message'), trigger: 'blur' },
      },
      company: this.$store.state.Company.data,
      sending: false,
    }
  },
  computed: {
    fromDetails() {
      return (`${this.account.fullName}\n${this.account.email}\n${this.account.companyName}`).trim()
    },
    account() {
      return this.$store.state.Auth.data
    },
    serviceEmail() {
      if (!this.marketplaceProfile) return null
      if (this.contactSellerForm.selectedServiceUid === null) return this.marketplaceProfile.email
      // From the helper mixin
      return this.emailForService(this.contactSellerForm.selectedServiceUid)
    },
    profileServices() {
      if (!this.marketplaceProfile) return []
      const viewable = Object.keys(this.marketplaceProfile.services).map(key => {
        const portInfo = this.$store.getters['Services/findPort'](key)
        return Object.assign(
          {
            productUid: key,
          },
          this.marketplaceProfile.services[key],
          {
            portInfo: portInfo,
          }
        )
      })

      return viewable
    },
  },
  methods: {
    setVisible(newValue) {
      this.$emit('update:visible', newValue)
    },

    handleClose(done) {
      this.setVisible(false)
      done()
    },

    emailForService(serviceUid) {
      if (!this.profileServices) return ''

      const selectedService = this.profileServices.find(s => serviceUid === s.productUid)

      // Try to use the service's contact email. If that's not set, use the company's email or an empty string if that is not set either.
      return selectedService?.contactEmail ? selectedService.contactEmail : (this.marketplaceProfile.email || '')
    },

    sendEmail() {
      this.$refs.contactSellerForm.validate(valid => {
        if (!valid) {
          const props = {
            title: this.$t('validations.failed'),
            message: this.$t('validations.correct-issues'),
            type: 'error',
            duration: 3000,
          }
          this.$notify(props)
        } else {
          this.sending = true
          const payload = {
            productUid: this.contactSellerForm.selectedServiceUid, // optional 'to', for a specific product
            companyUid: this.marketplaceProfile.companyUid, // to company
            personUid: this.$store.state.Auth.data.personUid, // from user
            message: escape(this.contactSellerForm.message),
            sendCopy: this.contactSellerForm.sendCopy,
          }
          sdk.instance
            .marketplace()
            .contact(payload)
            .then(() => {
              this.sending = false
              const props = {
                title: this.$t('marketplace.sent'),
                message: this.$t('marketplace.email-sent-to', { recipient: this.marketplaceProfile.companyName }),
                type: 'success',
                duration: 3000,
              }
              this.$notify(props)
              this.setVisible(false)
            })
            .catch(err => {
              this.sending = false
              // TODO: find out the expected response statuses for failures
              // and handle appropriately.
              if (err && err.data && err.data.message) {
                const props = {
                  title: this.$t('marketplace.send-error'),
                  message: err.data.message,
                  type: 'error',
                  duration: 3000,
                }
                this.$notify(props)
              }
              console.error(err)
            })
        }
      })
    },

    marketplaceName(service) {
      return service.title || service.portInfo.productName || service.portInfo.title
    },
  },
}
</script>

<style lang="scss" scoped>
.width-290px {
  width: 290px;
}

.from-details {
  position: relative;
  top: 1.2rem;
  margin-bottom: 14px;
  font-weight: 300;
  line-height: 1.2em;
  white-space: pre-line;
  word-break: normal;
}
</style>
