<template>
  <div v-if="value"
    class="service-profile"
    :class="{greyed: !active}">
    <!-- Component for sending message to the seller. -->
    <contact-seller :visible.sync="showContactSeller"
      :product-uid="service.portInfo.productUid"
      :marketplace-profile="marketplaceProfile" />

    <!-- Component for adding a new connection when we need to select A end -->
    <new-connection :visible.sync="showNewConnection"
      :product-uid="service.portInfo.productUid"
      :marketplace-profile="marketplaceProfile" />

    <div class="service-summary"
      @click.prevent="expanded = !expanded">
      <mu-mega-icon icon="vxc"
        class="service-icon service-profile-icon" />

      <div class="ml-6">
        <div v-if="service.portInfo">
          <h4>{{ theMarketplaceName }}</h4>
          <div v-if="service.portInfo.locationInfo && service.portInfo.locationInfo.formatted">
            {{ service.portInfo.connectType }}
            <template v-if="service.portInfo.buyoutPort">
              [{{ $t('ports.open-port') }}]
            </template> - {{ service.portInfo.locationInfo.formatted.long }}
          </div>
          <div v-if="['AWS', 'AWSHC'].includes(service.portInfo.connectType)">
            {{ $t(service.portInfo.connectType === 'AWSHC' ? 'aws.aws-hosted-connection' : 'aws.aws-vif') }}
            <el-tooltip v-if="service.portInfo.diversityZone"
              placement="top"
              :content="$t('connections.aws-diversity-tooltip')"
              :open-delay="500">
              <i class="far fa-dot-circle"
                :class="`diversity-color-${service.portInfo.diversityZone}`"
                aria-hidden="true" />
            </el-tooltip>
          </div>
          <div v-else-if="service.portInfo.diversityZone"
            :title="$t(`services.${service.portInfo.diversityZone}-zone`)">
            {{ $t('services.diversity-zone') }}
            <i class="far fa-dot-circle"
              :class="`diversity-color-${service.portInfo.diversityZone}`"
              aria-hidden="true" />
          </div>
        </div>
        <div v-if="service.serviceTypes.length > 0 && !(editing && active)"
          class="mt-0-5">
          <el-tag v-for="(tag, idx) in service.serviceTypes"
            :key="idx"
            type="info"
            size="small"
            class="mr-0-5">
            {{ tag.description }}
          </el-tag>
        </div>
      </div>

      <div class="mr-1 ml-auto">
        <template v-if="!ownProfile && !(editing && active)">
          <el-tooltip v-if="connect.active"
            placement="top"
            :content="$t('marketplace.create-vxc')"
            :open-delay="500">
            <el-button type="primary"
              name="addMarketplaceConnection"
              class="create-vxc-button"
              :data-serviceid="service.portInfo.productUid"
              @click="newConnection(connect.link)">
              <i class="fal fa-plus-circle"
                aria-hidden="true" /> {{ $t('connections.connection') }}
            </el-button>
          </el-tooltip>
          <el-tooltip v-else
            placement="top"
            :content="$t('marketplace.no-deployed-services')"
            :open-delay="500">
            <el-button type="primary"
              class="no-deployed-ports-button"
              @click="noAvailablePorts">
              <i class="fal fa-plus-circle"
                aria-hidden="true" /> {{ $t('general.service') }}
            </el-button>
          </el-tooltip>
        </template>

        <el-tooltip v-if="!(editing && active)"
          placement="top"
          :content="expanded ? $t('general.hide-details') : $t('general.show-details')"
          :open-delay="500">
          <el-button class="toggle-details-button"
            @click.stop="expanded = !expanded">
            <i class="fal fa-chevron-up expand-arrow"
              :class="expanded ? '' : 'active'"
              aria-hidden="true" />
          </el-button>
        </el-tooltip>
      </div>
    </div>

    <!-- Stuff to show if user wants to see details and are not editing -->
    <el-collapse-transition>
      <div v-if="expanded && !(editing && active)"
        class="service-details">
        <div class="flex-justify-center flex-wrap">
          <p>
            <span v-if="upTimeInfo"
              class="font-weight-400">{{ $t('general.status') }}:</span> {{ upTimeInfo }}
          </p>
          <el-button class="my-1 px-1 py-0 height-32px"
            @click="showContactSeller = true">
            <i class="fal fa-envelope"
              aria-hidden="true" />
            {{ $t('marketplace.contact') }}
          </el-button>
        </div>
        <p v-if="service.description"
          class="line-height-1-2em white-space-pre-line word-break-normal">
          {{ service.description }}
        </p>
      </div>
    </el-collapse-transition>

    <!-- Stuff to show when the user is editing -->
    <el-collapse-transition>
      <div v-if="editing && active"
        class="service-details">
        <el-form-item :label="$t('marketplace.display-name')"
          :prop="`eServices.${index}.title`">
          <el-input v-model="service.title"
            data-demo="Example Public Name"
            :placeholder="service.title"
            :name="`displayName-${index}`"
            @blur.native.capture="fillBlankServiceTitle" />
          <div v-if="isDuplicateTitle"
            class="el-form-item__error color-warning">
            {{ $t('marketplace.duplicate-name') }}
          </div>
        </el-form-item>

        <el-form-item :label="$t('general.description')"
          :prop="`eServices.${index}.description`"
          :rules="{validator: validateDescription, trigger:'change'}">
          <el-input v-model="service.description"
            type="textarea"
            :autosize="{ minRows: 3}"
            :placeholder="$t('marketplace.description')"
            :name="`description-${index}`" />
        </el-form-item>
        <p :class="(!service.description || service.description.trim().split(/[\s]+/).length <= maxWordLength) ? 'color-success' : 'color-danger'"
          class="text-align-right full-width mb-1 mx-0 mt-negative-1 font-weight-400">
          {{ wordCount }}
        </p>

        <el-form-item :label="$t('marketplace.service-contact-email')"
          :prop="`eServices.${index}.contactEmail`"
          :rules="{ type: 'email', message: $t('validations.email-invalid'), trigger: 'change' }">
          <el-input v-model="service.contactEmail"
            :placeholder="service.contactEmail"
            :name="`contactEmail-${index}`" />
        </el-form-item>

        <el-form-item v-if="serviceTypes.length > 0"
          class="unfloat-label">
          <template #label>
            {{ $t('marketplace.service-types') }}
            <el-tooltip placement="top"
              :content="$t('marketplace.select-services-destination')"
              :open-delay="500">
              <i class="fas fa-question-circle color-info popover-info-icon"
                aria-hidden="true" />
            </el-tooltip>
          </template>
          <el-checkbox-group v-model="serviceTypeIds">
            <div v-for="st in serviceTypes"
              :key="st.id">
              <el-checkbox :name="`services-${index}-${st.id}`"
                :label="st.id"
                class="line-height-0">
                {{ st.description }}
              </el-checkbox>
            </div>
          </el-checkbox-group>
        </el-form-item>
      </div>
    </el-collapse-transition>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import ContactSellerComponent from '@/components/marketplace/MarketplaceContactSeller.vue'
import NewConnectionComponent from '@/components/marketplace/MarketplaceNewConnection.vue'
import { DateTime } from 'luxon'
import { getLocaleLanguage } from '@/helpers'
import humanizeDuration from 'humanize-duration'
import { resolveServicesPage } from '@/utils/MapDataUtils.js'

export default {
  name: 'ServiceProfile',

  components: {
    'contact-seller': ContactSellerComponent,
    'new-connection': NewConnectionComponent,
  },

  props: {
    value: {
      type: Object,
      required: false,
      default: null,
    },
    editing: {
      type: Boolean,
      required: true,
    },
    serviceTypes: {
      type: Array,
      required: true,
    },
    initValue: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    marketplaceProfile: {
      type: Object,
      required: true,
    },
    isDuplicateTitle: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      expanded: false,
      company: this.$store.state.Company.data,
      service: {
        contactEmail: null,
        description: null,
        metadata: null,
        portInfo: {},
        serviceTypes: [],
        title: null,
      },
      maxWordLength: 200,
      serviceTypeIds: [],
      showContactSeller: false,
      showNewConnection: false,
    }
  },

  computed: {
    ...mapState('Services', ['services']),
    ...mapGetters('Services', ['canPortBeConnectedToByVxc']),
    ownProfile() {
      return this.company.companyUid === this.service.portInfo.companyUid
    },
    theMarketplaceName() {
      return this.service.title ? this.service.title : this.service.portInfo.productName || this.service.portInfo.title
    },
    active() {
      if (!this.service.portInfo.marketplaceVisibility && this.ownProfile) return false
      return true
    },
    connect() {
      const c = {
        active: false,
        link: '/',
      }
      if (!this.service) return c

      const connectable = this.services.filter(service => {
        return this.canPortBeConnectedToByVxc(service, this.service.portInfo)
      })

      if (connectable.length > 1) {
        // We will need to ask the user which of their available ports they want to connect from
        c.active = true
        c.link = null
      } else if (connectable.length === 1) {
        // Only one suitable port - automatically go to the add connection page
        c.active = true
        const payload = {
          targetUid: this.service.portInfo.productUid,
          sourceUid: connectable[0].productUid,
          connectType: this.service.portInfo.connectType,
        }

        c.link = `/create-connection/${payload.sourceUid}?bEnd=${payload.targetUid}&type=${payload.connectType}`
      }
      return c
    },
    wordCount() {
      let count = 0
      if (this.service.description && this.service.description.trim() !== '') {
        count = this.service.description.trim().split(/[\s]+/).length
      }
      return this.$t('general.word-counter', { count, maxWords: this.maxWordLength })
    },
    upTimeInfo() {
      let info = ''
      if (this.service.portInfo.provisioningStatus) {
        info += this.service.portInfo.provisioningStatus
      }
      if (this.service.portInfo.createDate) {
        if (info !== '') {
          info += ': '
        }

        const duration = DateTime.now()
          .diff(DateTime.fromMillis(this.service.portInfo.createDate))
          .milliseconds

        info += this.$t('marketplace.live-time', {
          time: humanizeDuration(duration, {
            language: getLocaleLanguage(this.$i18n.locale),
            largest: 1,
            units: ['y', 'mo', 'd', 'h', 'm'],
            round: true,
          }),
        })
      }

      return info
    },
  },

  watch: {
    service: {
      handler() {
        if (!this.editing) {
          return
        }
        this.$emit('input', this.service)
      },
      deep: true,
    },
    initValue(nv) {
      Object.assign(this.service, nv)
      this.serviceTypeIds = this.service.serviceTypes.map(element => {
        return element.id
      })
    },
    serviceTypeIds() {
      const filteredServiceTypes = this.serviceTypes.filter(st => {
        return this.serviceTypeIds.includes(st.id)
      })
      this.service.serviceTypes = filteredServiceTypes
    },
  },
  created() {
    Object.assign(this.service, this.initValue)
    this.serviceTypeIds = this.service.serviceTypes.map(element => {
      return element.id
    })
    this.tags = this.service.serviceTypes.map(t => t.description)
  },

  methods: {
    newConnection(link) {
      if (link) {
        this.$router.push(link, () => {
          // empty function is intentional
        })
      } else {
        this.showNewConnection = true
      }
    },
    validateDescription(rule, value, callback) {
      if (!value) {
        callback()
      } else if (value.length > 2000) {
        callback(this.$tc('validations.max-length', 2000, { max: 2000 }))
      } else if (value.trim().split(/[\s]+/).length > this.maxWordLength) {
        callback(this.$t('validations.description-too-long'))
      } else {
        callback()
      }
    },
    noAvailablePorts() {
      this.$confirm(this.$t('marketplace.no-services-message'), this.$t('marketplace.no-services-title'), {})
        .then(() => {
          this.$router.push(resolveServicesPage())
        })
        .catch(() => {
          // empty function is intentional
        })
    },
    fillBlankServiceTitle() {
      if (this.service.title) {
        return
      }
      this.service.title = this.$t('marketplace.company-at-location', { company: this.company.legalName, location: this.service.portInfo.locationInfo.name })
    },
  },
}
</script>

<style lang="scss" scoped>
.service-profile {
  background-color: var(--color-white);
  border: 1px solid var(--border-color-base);
  border-radius: var(--border-radius-base);
  margin: 1rem 0;
  &.greyed {
    background-color: var(--disabled-fill-base);
    color: var(--color-text-secondary);
    svg.service-icon {
      color: var(--color-text-secondary);
      fill: currentColor;
    }
  }
}

.service-profile-icon {
  margin-left: 1rem;
}

.service-summary {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  padding: 1rem 0;
  cursor: pointer;
}

.service-details {
  border-top: 1px solid var(--border-color-base);
  padding: 0 1rem;
}

.expand-arrow {
  transition: transform 0.3s;
  &.active {
    transform: rotate(180deg);
  }
  &:focus {
    outline: none;
  }
}

.toggle-details-button {
  padding: 0;
  width: 32px;
  height: 32px;
}
.create-vxc-button,
.no-deployed-ports-button {
  padding: 0;
  width: 138px;
  height: 32px;
}

.height-32px {
  height: 32px;
}
</style>
