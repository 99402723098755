<template>
  <el-dialog :visible="visible"
    :before-close="handleClose"
    :close-on-click-modal="false"
    :title="image ? $t('image-upload.select') : $t('image-upload.crop')">
    <form enctype="multipart/form-data"
      @submit.prevent>
      <div class="full-width height-400px">
        <div v-show="!image"
          id="dropZone"
          class="drop-file"
          :class="{'is-dragover': isDragOver}"
          @click="$refs.fileInput.click()">
          <i class="el-icon-upload fs-7rem"
            aria-hidden="true" />
          <div class="el-upload__text">
            {{ $t('image-upload.drop-image') }}
          </div>
        </div>
        <input v-if="!image"
          ref="fileInput"
          type="file"
          class="d-none"
          @change.prevent="inputSelect">

        <div v-show="image"
          id="imageContainer"
          class="image-container">
          <div id="overlay"
            class="crop-overlay">
            <div class="overlay-inner" />
          </div>
          <div id="resizeContainer"
            class="resize-container">
            <span class="resize-handle resize-handle-nw" />
            <span class="resize-handle resize-handle-ne" />
            <img id="cropImage"
              class="resize-image"
              :alt="$t('images.resize-handle-icon')"
              :src="image">
            <span class="resize-handle resize-handle-se" />
            <span class="resize-handle resize-handle-sw" />
          </div>
        </div>
      </div>
    </form>

    <template #footer>
      <el-button @click="setVisible(false)">
        {{ $t('general.cancel') }}
      </el-button>
      <el-button v-if="image"
        @click="image = null">
        {{ $t('image-upload.clear') }}
      </el-button>
      <el-button v-if="image"
        type="primary"
        @click="cropImage">
        {{ $t('general.done') }}
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
// This is set up for use of visible.sync, so when the dialog needs to close it emits the update message.
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      configuredForDragging: false,
      configuredForCropping: false,
      image: null,
      isDragOver: false,
      imageTarget: null,
      eventState: {},
      constrain: true,
      manipulatingImage: new Image(),
      resizeCanvas: null,
      minWidth: 50, // Change as required
      minHeight: 50,
      maxWidth: 500, // Change as required
      maxHeight: 400,
    }
  },

  watch: {
    image(newVal) {
      if (!newVal) {
        this.$nextTick(() => {
          this.setupForImageDrag()
          this.tearDownImageCrop()
        })
      } else {
        this.$nextTick(() => {
          this.tearDownImageDrag()
          this.setupForImageCrop()
        })
      }
    },
    visible() {
      this.image = null
      if (this.visible) {
        this.$nextTick(() => {
          // There is never an image passed in, so set up for drag and drop
          this.setupForImageDrag()
        })
      } else {
        this.$nextTick(() => {
          this.tearDownImageDrag()
          this.tearDownImageCrop()
        })
      }
    },
  },

  mounted() {
    this.image = null
  },

  methods: {
    setVisible(newValue) {
      this.$emit('update:visible', newValue)
    },
    handleClose(done) {
      this.setVisible(false)
      done()
    },

    setupForImageDrag() {
      if (this.configuredForDragging) {
        return
      }
      const dropZone = this.$el.querySelector('#dropZone')
      if (!dropZone) {
        console.error('Required element is missing')
        return
      }

      for (const eventType of ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop']) {
        dropZone.addEventListener(eventType, this.imageDragAllEvents, true)
      }

      for (const eventType of ['dragover', 'dragenter']) {
        dropZone.addEventListener(eventType, this.imageDragEnteredEvents, true)
      }

      for (const eventType of ['dragend', 'dragleave', 'drop']) {
        dropZone.addEventListener(eventType, this.imageDragExitedEvents, true)
      }

      for (const eventType of ['drop']) {
        dropZone.addEventListener(eventType, this.imageDropEvents, true)
      }

      this.configuredForDragging = true
    },
    tearDownImageDrag() {
      if (!this.configuredForDragging) {
        return
      }
      const dropZone = this.$el.querySelector('#dropZone')
      if (!dropZone) {
        console.error('Required element is missing')
        return
      }

      for (const eventType of ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop']) {
        dropZone.removeEventListener(eventType, this.imageDragAllEvents, true)
      }

      for (const eventType of ['dragover', 'dragenter']) {
        dropZone.removeEventListener(eventType, this.imageDragEnteredEvents, true)
      }

      for (const eventType of ['dragend', 'dragleave', 'drop']) {
        dropZone.removeEventListener(eventType, this.imageDragExitedEvents, true)
      }

      for (const eventType of ['drop']) {
        dropZone.removeEventListener(eventType, this.imageDropEvents, true)
      }

      this.configuredForDragging = false
    },
    imageDragAllEvents(e) {
      e.preventDefault()
      e.stopPropagation()
    },
    imageDragEnteredEvents() {
      this.isDragOver = true
    },
    imageDragExitedEvents() {
      this.isDragOver = false
    },
    imageDropEvents(e) {
      this.selectImage(e.dataTransfer.files)
    },

    setupForImageCrop() {
      if (this.configuredForCropping) {
        return
      }
      const resizeContainer = this.$el.querySelector('#resizeContainer')

      for (const eventType of ['mousedown', 'touchstart']) {
        const handles = resizeContainer.querySelectorAll('.resize-handle')
        for (const handle of handles) {
          handle.addEventListener(eventType, this.startResize, true)
        }
      }

      const image = resizeContainer.querySelector('img')
      for (const eventType of ['mousedown', 'touchstart']) {
        image.addEventListener(eventType, this.startMoving, true)
      }

      resizeContainer.style.left = `calc(50% - ${resizeContainer.offsetWidth / 2 - 2}px)`
      resizeContainer.style.top = `calc(50% - ${resizeContainer.offsetHeight / 2 - 2}px)`

      // When resizing, we will always use this copy of the original as the base
      const cropImage = this.$el.querySelector('#cropImage')
      this.manipulatingImage.src = cropImage.src

      this.eventState = {}
      this.resizeCanvas = document.createElement('canvas')
      this.configuredForCropping = true
    },
    tearDownImageCrop() {
      if (!this.configuredForCropping) {
        return
      }
      const resizeContainer = this.$el.querySelector('#resizeContainer')

      for (const eventType of ['mousedown', 'touchstart']) {
        const handles = resizeContainer.querySelectorAll('.resize-handle')
        for (const handle of handles) {
          handle.removeEventListener(eventType, this.startResize, true)
        }
      }

      const image = resizeContainer.querySelector('img')
      for (const eventType of ['mousedown', 'touchstart']) {
        image.removeEventListener(eventType, this.startMoving, true)
      }

      this.resizeCanvas = null
      this.configuredForCropping = false
    },
    startResize(e) {
      e.preventDefault()
      e.stopPropagation()
      this.saveEventState(e)

      for (const eventType of ['mousemove', 'touchmove']) {
        document.addEventListener(eventType, this.resizing, true)
      }
      for (const eventType of ['mouseup', 'touchend']) {
        document.addEventListener(eventType, this.endResize, true)
      }
    },
    endResize(e) {
      e.preventDefault()

      for (const eventType of ['mousemove', 'touchmove']) {
        document.removeEventListener(eventType, this.resizing, true)
      }
      for (const eventType of ['mouseup', 'touchend']) {
        document.removeEventListener(eventType, this.endResize, true)
      }
    },
    resizing(e) {
      let mouse = {},
        width,
        height,
        left,
        top

      mouse.x = (e.clientX || e.pageX || e.touches[0].clientX) + document.body.scrollLeft
      mouse.y = (e.clientY || e.pageY || e.touches[0].clientY) + document.body.scrollTop

      // Position image differently depending on the corner dragged and constraints
      const classList = this.eventState.evnt.target.classList
      if (classList.contains('resize-handle-se')) {
        width = this.eventState.container_width + (mouse.x - this.eventState.mouse_x)
        height = this.eventState.container_height + (mouse.y - this.eventState.mouse_y)
        left = this.eventState.container_left
        top = this.eventState.container_top
      } else if (classList.contains('resize-handle-sw')) {
        width = this.eventState.container_width - (mouse.x - this.eventState.mouse_x)
        height = this.eventState.container_height + (mouse.y - this.eventState.mouse_y)
        left = this.eventState.container_left + (mouse.x - this.eventState.mouse_x)
        top = this.eventState.container_top
      } else if (classList.contains('resize-handle-nw')) {
        width = this.eventState.container_width - (mouse.x - this.eventState.mouse_x)
        height = this.eventState.container_height - (mouse.y - this.eventState.mouse_y)
        left = this.eventState.container_left + (mouse.x - this.eventState.mouse_x)
        top = this.eventState.container_top + (mouse.y - this.eventState.mouse_y)
      } else if (classList.contains('resize-handle-ne')) {
        width = this.eventState.container_width + (mouse.x - this.eventState.mouse_x)
        height = this.eventState.container_height - (mouse.y - this.eventState.mouse_y)
        left = this.eventState.container_left
        top = this.eventState.container_top + (mouse.y - this.eventState.mouse_y)
      }

      // Optionally maintain aspect ratio
      if (this.constrain && !e.shiftKey) {
        if (classList.contains('resize-handle-se') || classList.contains('resize-handle-sw')) {
          height = (width / this.manipulatingImage.width) * this.manipulatingImage.height
        } else {
          const bottom = top + height
          const constrainedHeight = (width / this.manipulatingImage.width) * this.manipulatingImage.height
          top = bottom - constrainedHeight
          height = constrainedHeight
        }
      }

      if (width > this.minWidth && height > this.minHeight && width < this.maxWidth && height < this.maxHeight) {
        // To improve performance you might limit how often resizeImage() is called
        this.resizeImage(width, height)
        // Without this Firefox will not re-calculate the the image dimensions until drag end
        const resizeContainer = this.$el.querySelector('#resizeContainer')
        resizeContainer.style.left = `${left}px`
        resizeContainer.style.top = `${top}px`
      }
    },
    resizeImage(width, height) {
      this.resizeCanvas.width = width
      this.resizeCanvas.height = height
      this.resizeCanvas.getContext('2d').drawImage(this.manipulatingImage, 0, 0, width, height)
      const cropImage = this.$el.querySelector('#cropImage')
      cropImage.setAttribute('src', this.resizeCanvas.toDataURL('image/png'))
    },

    startMoving(e) {
      e.preventDefault()
      e.stopPropagation()
      this.saveEventState(e)

      for (const eventType of ['mousemove', 'touchmove']) {
        document.addEventListener(eventType, this.moving, true)
      }
      for (const eventType of ['mouseup', 'touchend']) {
        document.addEventListener(eventType, this.endMoving, true)
      }
    },
    endMoving(e) {
      e.preventDefault()
      for (const eventType of ['mousemove', 'touchmove']) {
        document.removeEventListener(eventType, this.moving, true)
      }
      for (const eventType of ['mouseup', 'touchend']) {
        document.removeEventListener(eventType, this.endMoving, true)
      }
    },
    moving(e) {
      let mouse = {}
      const touches = e.touches
      e.preventDefault()
      e.stopPropagation()

      mouse.x = (e.clientX || e.pageX || touches[0].clientX) + document.body.scrollLeft
      mouse.y = (e.clientY || e.pageY || touches[0].clientY) + document.body.scrollTop

      const resizeContainer = this.$el.querySelector('#resizeContainer')

      resizeContainer.style.left = `calc(50% - ${resizeContainer.offsetWidth / 2 - 2 - (mouse.x - this.eventState.mouse_x)}px)`
      resizeContainer.style.top = `calc(50% - ${resizeContainer.offsetHeight / 2 - 2 - (mouse.y - this.eventState.mouse_y)}px)`

      // Watch for pinch zoom gesture while moving
      if (this.eventState.touches && this.eventState.touches.length > 1 && touches.length > 1) {
        let width = this.eventState.container_width
        let height = this.eventState.container_height
        let a = this.eventState.touches[0].clientX - this.eventState.touches[1].clientX
        a = a * a
        let b = this.eventState.touches[0].clientY - this.eventState.touches[1].clientY
        b = b * b
        const dist1 = Math.sqrt(a + b)

        a = e.touches[0].clientX - touches[1].clientX
        a = a * a
        b = e.touches[0].clientY - touches[1].clientY
        b = b * b
        const dist2 = Math.sqrt(a + b)

        const ratio = dist2 / dist1

        width = width * ratio
        height = height * ratio
        // To improve performance you might limit how often resizeImage() is called
        this.resizeImage(width, height)
      }
    },

    saveEventState(e) {
      // Save the initial event details and container state
      const resizeContainer = this.$el.querySelector('#resizeContainer')
      this.eventState.container_width = resizeContainer.clientWidth
      this.eventState.container_height = resizeContainer.clientHeight

      // We want this to be relative to the parent element
      const imageContainer = this.$el.querySelector('#imageContainer')
      const parentRect = imageContainer.getBoundingClientRect()
      const childRect = resizeContainer.getBoundingClientRect()

      this.eventState.container_left = childRect.left - parentRect.left
      this.eventState.container_top = childRect.top - parentRect.top
      this.eventState.mouse_x = (e.clientX || e.pageX || e.touches[0].clientX) + document.body.scrollLeft
      this.eventState.mouse_y = (e.clientY || e.pageY || e.touches[0].clientY) + document.body.scrollTop

      // This is a fix for mobile safari
      // For some reason it does not allow a direct copy of the touches property
      if (typeof e.touches !== 'undefined') {
        this.eventState.touches = []
        Array.prototype.forEach.call(e.touches, function(i, ob) {
          this.eventState.touches[i] = {}
          this.eventState.touches[i].clientX = 0 + ob.clientX
          this.eventState.touches[i].clientY = 0 + ob.clientY
        })
      }
      this.eventState.evnt = e
    },

    inputSelect(e) {
      this.selectImage(e.target.files)
    },
    selectImage(files) {
      if (files.length < 1) return
      const file = files[0]
      if (!/^image\//i.test(file.type)) return
      const reader = new FileReader()
      reader.onload = fileObj => {
        this.image = fileObj.target.result
        //        this.image = fileObj.srcElement.result;
      }
      reader.readAsDataURL(file)
    },
    cropImage() {
      // Find the part of the image that is inside the crop box
      const overlay = this.$el.querySelector('#overlay')
      const resizeContainer = this.$el.querySelector('#resizeContainer')

      const overlayRect = overlay.getBoundingClientRect()
      const resizeRect = resizeContainer.getBoundingClientRect()

      const left = overlayRect.left - resizeRect.left
      const top = overlayRect.top - resizeRect.top
      const width = overlayRect.width
      const height = overlayRect.height

      this.resizeCanvas.width = width
      this.resizeCanvas.height = height

      const cropImage = this.$el.querySelector('#cropImage')

      const borderSize = 2
      this.resizeCanvas
        .getContext('2d')
        .drawImage(
          cropImage,
          left + borderSize,
          top + borderSize,
          width - borderSize * 2,
          height - borderSize * 2,
          0,
          0,
          width - borderSize * 2,
          height - borderSize * 2
        )
      const dataUri = this.resizeCanvas.toDataURL('image/png')

      this.$emit('input', dataUri)
      this.setVisible(false)
    },
  },
}
</script>

<style lang="scss" scoped>
.drop-file {
  height: 100%;
  border: 3px dashed var(--color-info);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &.is-dragover {
    background-color: var(--color-primary-light-9);
  }
}

.image-container {
  height: 400px;
  position: relative;
  overflow: hidden;
  border: 1px solid var(--card-border-color);
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
}

.crop-overlay {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -100px;
  margin-top: -100px;
  z-index: 999;
  width: 200px;
  height: 200px;
  border: solid 2px var(--color-primary);
  box-sizing: content-box;
  pointer-events: none;

  &:after,
  &:before {
    content: "";
    position: absolute;
    display: block;
    width: 200px;
    height: 40px;
    border-left: dashed 2px var(--color-primary);
    border-right: dashed 2px var(--color-primary);
  }

  &:before {
    top: 0;
    margin-left: -2px;
    margin-top: -40px;
  }

  &:after {
    bottom: 0;
    margin-left: -2px;
    margin-bottom: -40px;
  }

  .overlay-inner {
    &:after,
    &:before {
      content: "";
      position: absolute;
      display: block;
      width: 40px;
      height: 200px;
      border-top: dashed 2px var(--color-primary);
      border-bottom: dashed 2px var(--color-primary);
    }

    &:before {
      left: 0;
      margin-left: -40px;
      margin-top: -2px;
    }

    &:after {
      right: 0;
      margin-right: -40px;
      margin-top: -2px;
    }
  }
}

.resize-image {
  max-height: 400px;
  max-width: 100%;
}

.resize-container {
  position: relative;
  display: inline-block;
  cursor: move;
  margin: 0 auto;

  img {
    display: block;
  }

  &:hover,
  &:active {
    img {
      outline: 2px dashed var(--color-text-primary);
    }
  }

  .resize-handle {
    position: absolute;
    display: block;
    width: 10px;
    height: 10px;
    background: var(--color-text-primary);
    z-index: 999;
  }

  .resize-handle-nw {
    top: -5px;
    left: -5px;
    cursor: nw-resize;
  }

  .resize-handle-sw {
    bottom: -5px;
    left: -5px;
    cursor: sw-resize;
  }

  .resize-handle-ne {
    top: -5px;
    right: -5px;
    cursor: ne-resize;
  }

  .resize-handle-se {
    bottom: -5px;
    right: -5px;
    cursor: se-resize;
  }
}

.height-400px {
  height: 400px;
}

.fs-7rem {
  font-size: 7rem;
}
</style>
